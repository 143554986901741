import React, { useEffect } from 'react';
import { useBreakpoint } from '@noths/polaris-client-ribbons-base';
import {
  SimpleModal,
  SimpleModalBody,
  SolidButton,
} from '@noths/polaris-client-ribbons-design-system';
import { pxToRem } from '@noths/polaris-client-styles';

import {
  trackMembershipActivationModalCTAClick,
  trackMembershipActivationModalImpression,
} from 'src/tracking/components/membershipActivationModal';
import type { ContentType } from './types';
import * as styles from './MembershipActivationModal.styles';

interface MembershipActivationModalProps {
  content: ContentType;
  onModalClose: VoidFunction;
}

const WelcomeModalCopy = () => (
  <>
    <h2>Welcome to the club!</h2>
    <p>
      Your support means the world to our community of small businesses at{' '}
      <span css={styles.highlightedText}>Not On The High Street</span>. You can now enjoy 10% off
      every purchase for a whole year. *Yay*
    </p>
  </>
);

const DuplicatePurchaseModalCopy = () => (
  <>
    <h2>You&apos;re already a NOTHS & More member</h2>
    <p>
      You&apos;ve already got the benefits of our membership. We noticed you&apos;ve purchased it
      again, <span css={styles.highlightedText}>so we&apos;ll refund your latest purchase</span>. No
      need to worry - your membership is good to go. Thanks for being part of the NOTHS family.
    </p>
  </>
);

const AlreadyActivatedMembershipModalCopy = () => (
  <>
    <h2>Membership already activated!</h2>
    <p>
      Great news! Your NOTHS &amp; More membership is already active and ready to bring you
      exclusive benefits. If you have any questions or need assistance,{' '}
      <a
        css={styles.linkText}
        href="https://info.notonthehighstreet.com/hc/en-us/requests/new"
        rel="nofollow noreferrer"
        target="_blank"
      >
        our support team
      </a>{' '}
      is here to help. Enjoy your enhanced shopping experience!
    </p>
  </>
);

const getCopyComponent = (content: ContentType) => {
  switch (content) {
    case 'alreadyAMember':
      return DuplicatePurchaseModalCopy;
    case 'alreadyActivated':
      return AlreadyActivatedMembershipModalCopy;
    default:
      return WelcomeModalCopy;
  }
};

export const MembershipActivationModal = ({
  content,
  onModalClose,
}: MembershipActivationModalProps) => {
  const breakPoint = useBreakpoint();

  const smallScreenSizes = ['XS', 'S'];

  const simpleModalContentStyle = {
    maxWidth: smallScreenSizes.includes(breakPoint) ? pxToRem(360) : pxToRem(768),
  };

  useEffect(() => trackMembershipActivationModalImpression(content), [content]);

  const handleClick = () => {
    trackMembershipActivationModalCTAClick(content);
    onModalClose();
  };

  const MembershipActivationCopy = getCopyComponent(content);

  return (
    <>
      <div css={styles.overlay} data-testid="overlay" />
      <SimpleModal
        contentLabel="Activation Modal"
        contentStyles={simpleModalContentStyle}
        isOpen
        onRequestClose={onModalClose}
      >
        <SimpleModalBody>
          <div css={styles.activationModal(content)}>
            <img
              alt=""
              src="https://images.contentstack.io/v3/assets/blt83ec2ae5e0dbde7f/blt141c2ffd74085270/6540fcfaf50283001b39c9ce/noths-more-logo-color.png"
            />
            <MembershipActivationCopy />
            <div>
              <SolidButton onClick={handleClick}>Start Shopping</SolidButton>
            </div>
          </div>
        </SimpleModalBody>
      </SimpleModal>
    </>
  );
};
